@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');


.buttonfont {
    font-family: 'lato';
    font-weight: 400;
}

.buttonstyle {
    padding: 10px 30px;
}

.bordercolor {
    border-bottom-width: 2px;
}
@keyframes slide {
  0% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(-500px);
  }
  100% {
      transform: translateX(0);
  }
}

.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: yellow;
  border-color: black;
  border-width: 1px;
}

.slider-content {
  display: flex;
  flex-direction: row;
  animation: slide 10s linear infinite;
}

.slidertext {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  /* background-color: red; */
  flex-shrink: 0;
  white-space: nowrap;
  padding: 10px 5vw; /* Adjust the padding for spacing */
}

.programmes-items {
  font-family: 'raleway';
}

.faqtransition {
    animation: fadeInOut 0.5s ease-in-out;
}
.faqtransition1 {
    animation: fadeOut 0.5s ease-in-out;
}

@keyframes fadeInOut {
    0% {
      opacity: 0;
      max-height: 0;
    }
    100% {
      opacity: 1;
      max-height: 1000px;
    }
  }
@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      height: 0;
    }
  }